<template>
  <div class="preview post-breadcrumb-spacer">
    <b-container>
      <div class="text-center mb-4">
        <h2 class="font-weight-normal">
          Here's a preview of your lesson plan
        </h2>
      </div>
      <b-row v-if="courseObj" class="justify-content-center pt-4 mb-4">
        <b-col cols="12" xl="7" lg="8"
               class="mb-5 mb-lg-0">
          <h4 class="font-weight-bold pb-3">
            {{ courseObj.version_display || courseObj.year }}
            {{ courseObj.name }} breakdown
          </h4>
          <b-card no-body>
            <div class="py-2 px-3">
              <b-row class="header font-weight-bold border-0 pt-2 pb-3 px-0">
                <b-col cols="1" class="d-none d-sm-block">
                  #
                </b-col>
                <b-col cols="5" md="6">
                  <span class="d-none d-sm-inline">Assignment name</span>
                  <span class="d-block d-sm-none font-size-12">Assignment</span>
                </b-col>
                <b-col cols="3" md="2">
                  <span class="d-none d-sm-inline">Lessons</span>
                  <span class="d-block d-sm-none font-size-12">Lessons</span>
                </b-col>
                <b-col cols="4" sm="3">
                  <span class="d-none d-sm-inline">Duration</span>
                  <span class="d-block d-sm-none font-size-12">Duration</span>
                </b-col>
              </b-row>
              <b-row v-for="(section, $sectionIndex) in courseObj.sections" :key="section.id"
                     v-b-toggle="'accordion' + section.order" class="pointer border-top pt-2 pb-3 px-0">
                <b-col cols="1" class=" d-none d-sm-block" font-weight-bold>
                  {{ $sectionIndex + 1 }}
                </b-col>
                <b-col cols="5" md="6">
                  {{ section.name | striphtml }}
                </b-col>
                <b-col cols="3" md="2">
                  {{ section.num_lessons }}
                </b-col>
                <b-col cols="4" sm="3">
                  {{ duration(section.duration) }}
                </b-col>
                <b-collapse :id="'accordion' + section.order" :class="'col-12 pt-2 px-0'">
                  <b-row v-for="(lesson, $lessonIndex) in section.lessons" :key="lesson.id"
                         class="border-0 py-2 px-0 m-0">
                    <b-col v-if="$lessonIndex === 0" cols="11" class="offset-1 border-top pb-2" />
                    <b-col cols="6" class="offset-sm-1 text-muted">
                      <small> {{ lesson.name |striphtml }} </small>
                    </b-col>
                    <b-col cols="3" class="offset-2 text-muted">
                      <small>{{ duration(lesson.duration) }}</small>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" xl="5" lg="4">
          <h4 class="font-weight-bold pb-3">
            Lesson plan options
          </h4>
          <b-card no-body class="p-4 mb-4">
            <b-form>
              <b-form-group label="Number of test attempts:" label-for="attempts">
                <b-form-select id="attempts" v-model="settings.attempts" :options="attemptsOptions" />
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="settings.baseline" :class="'pb-3 d-block'">
                  <div>Include baseline test *</div>
                  <small>*This setting cannot be changed later</small>
                </b-form-checkbox>
                <b-form-checkbox v-model="settings.timer" :class="'pb-3 d-block'">
                  Include testing timer
                </b-form-checkbox>
                <b-form-checkbox v-model="settings.autoplay" :class="'pb-3 d-block'">
                  Autoplay videos upon completion <br> and allow speed adjustment
                </b-form-checkbox>
                <b-form-checkbox v-model="settings.prevNext" :class="'pb-3 d-block'">
                  Include previous/next buttons
                </b-form-checkbox>
              </b-form-group>
            </b-form>
          </b-card>
          <div class="text-right">
            <b-button class="btn btn-primary" variant="primary" type="button"
                      :disabled="disableButton" @click="complete">
              Complete lesson plan
            </b-button>
          </div>
        </b-col>
      </b-row>

      <sp-loader v-if="isLoading" />
    </b-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';

export default {
  name: 'LessonPlanPreview',
  extends: BaseComponent,
  data() {
    return {
      attemptsOptions: [
        {text: 'Unlimited', value: null},
        {text: '1', value: 1},
        {text: '2', value: 2},
        {text: '3', value: 3},
        {text: '4', value: 4},
        {text: '5', value: 5},
      ],
      settings: {
        attempts: null,
        autoplay: false,
        baseline: false,
        prevNext: false,
        timer: false,
      },
      disableButton: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['lessonBuilder/getPlan']) {
        next({name: 'LessonPlanSelect'});
      } else {
        next();
      }
    });
  },
  computed: {
    plan() {
      return this.$store.getters['lessonBuilder/getPlan'];
    },
    subject() {
      return this.$store.getters['lessonBuilder/getSubject'];
    },
    name() {
      return this.$store.getters['lessonBuilder/getName'];
    },
    code() {
      return this.$store.getters['lessonBuilder/getCode'];
    },
    courseObj() {
      return this.$store.getters['software/getCourseBySlug']({
        software: this.subject.slug,
        course: this.plan.slug,
      });
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // if the full course object hasn't been retrieved, retrieve it JCS07202018
      if (this.courseObj && this.courseObj.sections) {
        // already loaded, don't load again
        this.isLoading = false;
      } else {
        this.isLoading = true;
        this.$store.dispatch('software/loadCourseBySlug', {software: this.subject.slug, course: this.plan.slug})
          .then(
            (response) => {
              this.isLoading = false;
            }
          );
      }
    },
    /**
     * Get hours and minutes of a duration JCS07202018
     * @returns {string}
     * @param time
     */
    duration(time) {
      let minutes = Math.floor(time / 60);
      let seconds = time % 60;
      return minutes + 'm ' + seconds + 's';
    },
    /**
     * Complete the process, send data to API for saving
     */
    complete() {
      this.disableButton = true;
      let planData = {
        name: this.name,
        courseId: this.courseObj.id,
        settings: Object.assign(this.settings, {classCode: this.code}),
      };
      this.$store.dispatch('lessonBuilder/createMultigroup', planData).then(
        (response) => {
          // get newly created mgid and redirect to mg page JCS07202018
          this.analyticsClickEvent('Multigroup', 'Create - Lesson Plan', 'courseid: ' + this.courseObj.id + ', mgid: ' + response.id);
          this.redirectUrl(this.SPENV.APP_URL + '/lmsapp/multigroups/list/' + response.id);
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
